import React from "react";
import { useStateValue } from "../../contexto/store";
import { Route, Redirect } from "react-router-dom";
import NoPermitido from "vistas/seguridad/NoPermitido";
import useRoles from 'hooks/useRoles';

function RutaSegura(props) {
  const [{ sesionUsuario }] = useStateValue();
  const [roles, accesos, isLoading] = useRoles();

  // Si se encuentra la sesión del usuario
  if (sesionUsuario && sesionUsuario.autenticado === true) {
    if (!isLoading) {
      // Si no está permitido el acceso para cierto rol
      if (roles !== undefined && props.noRoles !== undefined && props.noRoles.some !== undefined && props.noRoles.some(r => roles.includes(r))) {
        return (<NoPermitido/>);
      }
      // Si se requiere cierto acceso para ingresar al modulo
      if (accesos !== undefined && props.permisos !== undefined && ((Array.isArray(props.permisos) && accesos.some(a => props.permisos.includes(a.name))) || (!Array.isArray(props.permisos) && accesos.some(a => a.name === props.permisos)))) {
        return (<Route {...props}/>);
      }
      // Si se requieren ciertos roles para ingresar al modulo
      if (roles !== undefined && props.roles !== undefined && props.roles.some !== undefined && props.roles.some(r => roles.includes(r))) {
        return (<Route {...props}/>);
      }
      // Si no se requiere permisos ni roles
      if (props.permisos === undefined && props.roles === undefined) {
        return (<Route {...props}/>)
      }
      // Si no tiene acceso al modulo
      return (<NoPermitido/>);
    } else {
      // Si aún se estan cargando los roles
      return (<div></div>);
    }
  } else {
    // Si no existen las credenciales
    return (<Redirect to={{ pathname: "/auth/login", state: { from: props.location}}}/>);
  }

  /*return (sesionUsuario && sesionUsuario.autenticado === true)
    ? !isLoading
      ? (
          (
            (accesos !== undefined && props.permisos !== undefined && accesos.some(a => a.name === props.permisos))
            || (roles !== undefined && props.roles !== undefined && props.roles.some !== undefined && props.roles.some(r => roles.includes(r)))
          ) 
          || (props.permisos === undefined && props.roles === undefined) // Si no se requiere permisos ni roles
        )
        ? (<Route {...props}/>)
        : (<NoPermitido/>)
      : (<div></div>)
    : (<Redirect to={{ pathname: "/auth/login", state: { from: props.location}}}/>);*/
}

export default RutaSegura;
